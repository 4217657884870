import * as React from "react"
import {GatsbySeo} from 'gatsby-plugin-next-seo';
import MainLayout from "../components/mainLayout/MainLayout"
import PostEntry from "../components/postEntry/PostEntry"
import {graphql} from "gatsby";
import config from "../config/config"


const IndexPage = ({data}) => {
    const {allMarkdownRemark, site} = data
    const siteTitle = site.siteMetadata.title || ''
    const siteAuthor = site.siteMetadata.author || ''
    const siteBaseUrl = site.siteMetadata.siteUrl || ''
    const siteDescription = site.siteMetadata.description || ''
    const canonical = `${siteBaseUrl}/`
    const transformedLocale = config.meta.locale.toLowerCase().replace("_", "-")
    // TODO: add category image
    const image = `${siteBaseUrl}${allMarkdownRemark.edges[0].node.frontmatter.featuredImage.publicURL}`
    allMarkdownRemark.edges.length = 9
    return (
        <MainLayout showH1={true} activeItem="/">
            <GatsbySeo
                title={siteTitle}
                titleTemplate={`Inicio | ${siteTitle}`}
                description={siteDescription}
                language={config.meta.lang}
                metaTags={[
                    {property: 'author', name: 'author', content: siteAuthor},
                    {property: 'copyright', name: 'copyright', content: siteAuthor},
                    {property: 'publisher', name: 'publisher', content: siteAuthor},
                    {property: 'content-language', name: 'content-language', content: transformedLocale}
                ]}
                openGraph={{
                    title: `Inicio | ${siteTitle}`,
                    description: siteDescription,
                    url: canonical,
                    type: 'website',
                    images: [
                        {
                            url: image,
                            width: 1200,
                            height: 630,
                            alt: `Inicio | ${siteTitle}`,
                        },
                    ],
                }}
                twitter={{
                    site: siteAuthor,
                    handle: siteAuthor,
                    cardType: 'summary_large_image'
                }}
            />
            <p>Bienvenidos a la página de htbwriteups.com.</p>
            <p>Aquí encontrarás diferentes writeups y manuales para solucionar algunos de los retos propuestos en HackTheBox.</p>
            <p>Siéntete libre de utilizarlos para ayudarte en tu camino al éxito de la ciberseguridad.</p>
            <hr/>
            <h2>Últimas máquinas resueltas</h2>
            <br/>
            {
                allMarkdownRemark.edges.map((e, idx) =>
                    (
                        <PostEntry
                            key={`${e.node.frontmatter.title}-${idx}`}
                            title={e.node.frontmatter.title}
                            href={`/${e.node.frontmatter.categories[0]}/${e.node.frontmatter.slug}/`}
                            image={e.node.frontmatter.thumb}
                            excerpt={e.node.frontmatter.excerpt}
                            tags={e.node.frontmatter.tags}
                            os={e.node.frontmatter.os}
                            dificultad={e.node.frontmatter.dificultad}
                        />
                    )
                )
            }
        </MainLayout>
    )
}


export const query = graphql`
    query indexQuery {
        allMarkdownRemark {
            edges {
                node {
                    frontmatter {
                        slug
                        tags
                        dificultad
                        os
                        thumb
                        title
                        excerpt
                        description
                        categories
                        date(formatString: "MMM D, YYYY")
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid {
                                    sizes
                                    srcSet
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
                author
                description
            }
        }
    }
`

export default IndexPage
